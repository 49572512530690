<template>
  <div class="card balance-block">
    <div class="balance-container">
      <div class="balance-header">
        <div class="balance-label">{{ $t("label.balance.your") }}:</div>
        <div class="balance">
          {{ balance }}
          <span class="currency-symbol">{{ currencySymbol }}</span>
        </div>
      </div>

      <ButtonElement
        data-cy="add-withdrawal"
        class="withdrawal-btn"
        variant="primary"
        text="createWithdrawal"
        @click="onClickAddWithdrawal"
      />

      <div class="balance-footer">
        <div class="withdrawn-label">{{ $t("label.balance.period") }}:</div>
        <div class="withdrawn">
          {{ agentWithdrawn }}
        </div>
      </div>
    </div>

    <AddRequisitesModal
      ref="AddRequisitesModal"
      :requisite="requisite"
      @saveRequisite="saveRequisite"
    />
  </div>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { createWithdrawal, updateProfile } from "../gql/mutations";
import { mapState } from "vuex";

import KTCard from "@/components/card/KTCard";
import ButtonElement from "@/components/ButtonElement";
import AddRequisitesModal from "@/components/AddRequisitesModal.vue";
import notify from "@/services/notify.service";

export default {
  name: "BalanceBlock",

  components: {
    KTCard,
    ButtonElement,
    AddRequisitesModal,
  },

  mixins: [apolloMixin],

  props: {
    balance: {
      type: Number,
      default: 0,
    },

    withdrawn: {
      type: Number,
      default: 0,
    },

    requisite: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapState("currency", ["currencySymbol"]),

    agentWithdrawn() {
      return `${this.withdrawn} ${this.currencySymbol}`;
    },
  },

  methods: {
    onClickAddWithdrawal() {
      if (!this.requisite.iban) {
        this.$refs.AddRequisitesModal.showModal();

        return;
      }

      this.AddWithdrawal();
    },

    async saveRequisite(form) {
      await this.$post(updateProfile, form);
      await this.AddWithdrawal();
    },

    async AddWithdrawal() {
      const createdWithdrawal = await this.$post(createWithdrawal, {}, { withNotify: false });

      if (createdWithdrawal) {
        this.$emit("withdrawalCreated");

        notify.success("createWithdrawal");
      } else {
        notify.warning("pendingWithdrawalAlreadyExists");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.balance-block:deep() {
  height: 100%;
  padding: 40px 40px 16px 40px;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 100%;

  .card-body {
    padding: 0;
    margin: 0;
    height: auto;
  }
}

.balance-container {
  display: flex;
  flex-direction: column;

  .balance-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    padding: 0;

    .balance {
      font-weight: 900;
      font-size: 40px;
      line-height: 47px;

      &-label {
        font-size: 14px;
        line-height: 16px;
        color: #8c8c8c;
        margin-bottom: 16px;
      }

      .currency-symbol {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  .balance-footer {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .withdrawn {
      font-size: 12px;
      line-height: 14px;
      color: #156fc1;

      &-label {
        font-size: 12px;
        line-height: 14px;
        color: #8c8c8c;
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: 720px) {
  .balance-block {
    height: 268px;

    .header {
      margin-bottom: 60px;
    }
  }
}
</style>
