import gql from "graphql-tag";

export const dashboardData = gql`
  query dashboardData($dateFrom: YearMonth, $dateTo: YearMonth) {
    balance {
      amount
      withdrawn
    }

    referralCount(dateFrom: $dateFrom, dateTo: $dateTo) {
      viewsCount
      totalCount
      inProgressCount
      wonCount
    }
  }
`;
