<template>
  <PageModal
    ref="dashboardModal"
    modal-id="dashboardPasswordModal"
    class="dashboard-modal dashboard-password-modal"
    modal-size="xl"
    is-centered
    hide-header
    hide-footer
    body-class="p-0"
    content-class="rounded-lg"
  >
    <div class="dashboard-modal-container">
      <div class="instr_modal-welc">
        <div>
          <p class="headline1">{{ $t("passwordModal.title") }}</p>
        </div>
      </div>

      <div class="instr_modal-info">
        <b-form novalidate>
          <InputElement
            v-model="form.password"
            data-cy="password-input"
            class="mb-10"
            required
            :label="$t('label.password._')"
            input-type="password"
            :with-error="errors.password.value"
            :error-message="errors.password.msg"
            @blur="onBlur"
          />
          <InputElement
            v-model="form.password_confirmation"
            data-cy="password_confirmation-input"
            class="mb-10"
            required
            :label="$t('label.password.verify')"
            input-type="password"
            :with-error="errors.password_confirmation.value"
            :error-message="errors.password_confirmation.msg"
            @blur="onBlur"
          />
          <button class="but but_mod btn-fullwidth" :disabled="formError" @click="submit">
            {{ $t("passwordModal.sendBtn") }}
          </button>
        </b-form>
      </div>
    </div>
  </PageModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import "@/assets/css/main.css";

import PageModal from "@/components/modal/PageModal.vue";
import InputElement from "@/components/form/InputElement";
import SvgIcon from "@/components/svg/SvgIcon.vue";

export default {
  name: "PasswordModal",

  components: {
    SvgIcon,
    PageModal,
    InputElement,
  },

  data() {
    return {
      form: {
        password: "",
        password_confirmation: "",
      },
      errors: {
        password: {
          value: false,
          msg: "",
        },
        password_confirmation: {
          value: false,
          msg: "",
        },
      },
      validateProcess: false,
    };
  },

  computed: {
    ...mapGetters(["newUserState", "userProfile"]),
    formError() {
      let error = false;

      for (let k in this.errors) {
        if (this.errors[k].value) {
          error = true;
          break;
        }
      }

      if (this.form.password == "" || this.form.password_confirmation == "") {
        error = true;
      }

      return error;
    },
  },

  watch: {
    userProfile: {
      handler: function () {
        if (this.userProfile.detail.passwordChanged != 0) {
          this.closeModal();
          this.$bvToast.toast(this.$t("passwordModal.success"), {
            toaster: "b-toaster-bottom-left",
            variant: "success",
            solid: true,
          });
        }
      },
      deep: true,
    },
    form: {
      handler: "validate",
      deep: true,
    },
  },

  mounted() {
    if (this.userProfile.detail.passwordChanged == 0) {
      this.openModal();
    }
  },

  methods: {
    ...mapActions(["setPassword"]),

    openModal() {
      this.$refs.dashboardModal.showModal();
    },

    closeModal() {
      this.$refs.dashboardModal.closeModal();
    },

    submit(event) {
      event.preventDefault();

      this.setPassword(this.form);
    },

    onBlur() {
      this.validateProcess = true;
      this.validate();
    },

    validate() {
      if (this.form.password !== "" && this.form.password.length >= 8) {
        if (this.validateProcess) {
          this.errors.password.value = false;
          this.errors.password.msg = "";
        }
      }

      if (this.form.password !== "" && this.form.password.length < 8) {
        if (this.validateProcess) {
          this.errors.password.value = true;
          this.errors.password.msg = this.$t("validation.passwordMinLength", { length: 8 });
        }
      }

      if (
        this.form.password !== "" &&
        this.form.password_confirmation !== "" &&
        this.form.password !== this.form.password_confirmation
      ) {
        if (this.validateProcess) {
          this.errors.password.value = true;
          this.errors.password_confirmation.value = true;

          this.errors.password.msg = this.$t("validation.passwordsMustMatch");
          this.errors.password_confirmation.msg = this.$t("validation.passwordsMustMatch");
        }
      } else if (this.form.password === "" && this.form.password_confirmation === "") {
        if (this.validateProcess) {
          this.errors.password.value = false;
          this.errors.password_confirmation.value = false;
        }
      } else if (
        this.form.password.length >= 8 &&
        this.form.password !== "" &&
        this.form.password_confirmation !== "" &&
        this.form.password === this.form.password_confirmation
      ) {
        this.errors.password.value = false;
        this.errors.password_confirmation.value = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-password-modal {
  .modal-backdrop {
    pointer-events: none;
  }
}
.dashboard-modal-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 599px;
  max-width: 1200px;
  position: relative;

  .instr_modal-welc {
    height: 100%;
    width: 50%;
    background: #5cc2bc;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .headline1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 64px;
  }

  .instr_modal-info {
    width: 50%;
    padding: 80px 40px;
  }

  .instr_modal-close-icon {
    position: absolute;
    top: 10px;
    right: 0;
    opacity: 0.7;
  }

  .instr_modal-h3 {
    font-weight: 700;
    font-size: 20px;
    padding-left: 48px;
    margin-bottom: 32px;
  }

  .instr_modal-info .insturct_step-head {
    margin-bottom: 24px;
  }

  .instr_modal-info .but {
    margin-top: 80px;
  }

  .btn-fullwidth {
    width: 100%;
  }
  .but {
    &:disabled {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: 1200px) {
  .dashboard-modal-container {
    flex-direction: column;
    height: 100%;

    .instr_modal-welc,
    .instr_modal-info {
      width: 100%;
    }

    .instr_modal-welc {
      text-align: center;
      margin: 0 auto;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 0;

      p {
        margin: 0 auto;
        width: 70%;
        white-space: normal;
      }

      .headline1 {
        margin-bottom: 40px;
      }

      br {
        display: none;
      }
    }

    .instr_modal-close-icon {
      opacity: 1;

      &:deep {
        g [fill] {
          fill: #d9d9d9 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .dashboard-modal-container {
    .instr_modal-info .but {
      margin: 60px auto 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-modal-container {
    height: auto;

    .instr_modal {
      align-items: stretch;
      flex-direction: column;
    }

    .instr_modal-welc {
      height: 100%;
      width: 100%;
      padding: 64px;
    }

    .instr_modal-welc > div br {
      display: none;
    }

    .instr_modal-info {
      width: 100%;
      padding: 48px 70px 80px;
    }

    .instr_modal-info .but {
      margin: 80px auto 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard-modal-container {
    .instr_modal-welc {
      width: 100%;
      padding: 64px 40px 40px;
    }

    .instr_modal-info {
      width: 100%;
      padding: 40px 24px 46px;
    }

    .instr_modal-info .but {
      margin: 50px auto 0;
    }
  }
}

@media (max-width: 600px) {
  .dashboard-modal-container {
    .headline1 {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }
}
</style>
