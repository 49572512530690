<template>
  <div class="card">
    <span class="icon svg-icon svg-icon-2x">
      <slot name="svg" />
    </span>

    <div class="main-info">
      <span class="main-label">{{ label }}</span>
      <span class="counter" :data-cy="cyCounterName"> {{ getFormatCount(count) }}</span>
    </div>

    <div class="sub-info">
      <span class="sub-label">{{ sublabel }}</span>
      <span class="sub-counter"> {{ getFormatCount(subcount) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsWidget",
  props: {
    count: {
      type: [Number, String],
      default: 0,
    },

    subcount: {
      type: [Number, String],
      default: 0,
    },

    label: {
      type: String,
      default: "",
    },

    sublabel: {
      type: String,
      default: "",
    },

    cyCounterName: {
      type: String,
      default: "",
    },
  },

  methods: {
    getFormatCount(count) {
      return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>

<style lang="scss" scoped>
.card .icon .btn[data-v-575da4e9]:not(:disabled):not(.disabled),
.btn:not(:disabled):not(.disabled) {
  cursor: auto !important;
}

.card {
  height: 122px;
  padding: 16px 20px 16px 24px;
  position: relative;
  min-width: 320px;
  width: 100%;

  .icon {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
  }

  .main-info {
    padding: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    text-align: left;

    .main-label {
      font-size: 14px;
      line-height: 16px;
      color: #8c8c8c;
    }

    .counter {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }
  }

  .sub-info {
    .sub-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #8c8c8c;
    }

    .sub-counter {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #156fc1;
    }
  }
}
</style>
