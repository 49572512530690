<template>
  <KTCard class="card-table p-6 bg-dark">
    <div class="d-flex align-items-center justify-content-between">
      <div class="w-100">
        <div class="text-link">
          <span class="font-weight-bold text-white-50">
            {{ $t("label.referral.yourLink.start") }}
          </span>
          <span class="referral-btn" @click="onClickAddReferral">
            {{ $t("label.referral.yourForm") }}
          </span>
          <span class="font-weight-bold text-white-50">
            {{ $t("label.referral.yourLink.end") }}
          </span>
        </div>

        <b-link data-cy="ref-link" :href="referralLink" target="_blank">
          <b-input ref="link" readonly class="referral-link" :value="referralLink" />
        </b-link>
      </div>

      <SvgIcon
        data-cy="link-copy-icon"
        class="p-0"
        icon-folder="General"
        icon-name="Duplicate"
        icon-color="white"
        icon-size="lg"
        @click="onClickCopy"
      />
    </div>
  </KTCard>
</template>

<script>
import { mapGetters } from "vuex";
import KTCard from "@/components/card/KTCard";
import SvgIcon from "@/components/svg/SvgIcon";
import notify from "@/services/notify.service";

export default {
  name: "ReferralLinkBlock",
  components: {
    KTCard,
    SvgIcon,
  },

  computed: {
    ...mapGetters(["userHash"]),

    referralLink() {
      return `${window.location.origin}/landing?agent=${this.userHash}`;
    },
  },

  methods: {
    onClickAddReferral() {
      this.$router.push({ name: "AgentReferralAdd" });
    },

    onClickCopy() {
      try {
        this.$refs.link.select();

        document.execCommand("copy");

        notify.success("copiedSuccessfully");
      } catch (error) {
        notify.error("copyError");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.referral-btn {
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;
}

.referral-link {
  cursor: pointer;
  font-size: 22px;
  background-color: inherit;
  color: #ffffff;
  border: none;
  padding: 0;
  font-weight: 500;
  height: 100%;
  margin: 0.28rem 0 0.5rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 575.98px) {
  .referral-link {
    font-size: 16px;
  }
}
</style>
