<template>
  <div data-cy="dashboard-page">
    <Content :dashboard-data="dashboardData" @withdrawalCreated="getDashboardData" />
    <PasswordModal />
    <Modal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { dashboardData } from "./gql/queries";
import DateService from "@/services/date.service";

import Content from "./components/Content";
import Modal from "./components/Modal";
import PasswordModal from "./components/PasswordModal.vue";

const CONTENT_WIDTH = 1200;

export default {
  name: "Dashboard",

  components: {
    Content,
    Modal,
    PasswordModal,
  },

  mixins: [apolloMixin],

  data() {
    return {
      requisite: {},
      balance: 0,
      withdrawn: 0,
      referralCount: {},
      referralYearCount: {},
    };
  },

  computed: {
    ...mapGetters(["userRequisites"]),

    dashboardData() {
      return {
        requisite: this.requisite,
        balance: this.balance,
        withdrawn: this.withdrawn,
        referralCount: this.referralCount,
        referralYearCount: this.referralYearCount,
      };
    },
  },

  created() {
    this.getDashboardData();
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    getDashboardData() {
      this.getData();
      this.getLastYearData();
    },

    async getData() {
      const { balance, referralCount } = await this.$get(dashboardData);

      this.balance = balance.amount;
      this.withdrawn = balance.withdrawn;
      this.referralCount = referralCount;
      this.requisite = { ...this.userRequisites };
    },

    async getLastYearData() {
      const date = DateService.getCurrentYearPeriod();

      const { referralCount } = await this.$get(dashboardData, {
        dateFrom: date.startDate,
        dateTo: date.endDate,
      });

      this.referralYearCount = referralCount;
    },
  },
};
</script>
