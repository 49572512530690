<template>
  <div class="dashboard-content">
    <div class="main-info">
      <div class="main-info-block">
        <StatsWidget
          class="mb-6 stats-widget"
          data-cy="referral-counter-block"
          cy-counter-name="referral-counter"
          :subcount="dashboardData.referralCount.viewsCount"
          :count="dashboardData.referralYearCount.viewsCount"
          :label="$t('label.views._')"
          :sublabel="$t('label.views.period')"
        >
          <template v-slot:svg>
            <SvgIcon
              class="stats-widget-icon"
              icon-folder="General"
              icon-name="Visible"
              icon-color="primary"
            />
          </template>
        </StatsWidget>
        <StatsWidget
          class="mb-6 stats-widget"
          :subcount="dashboardData.referralCount.inProgressCount"
          :count="dashboardData.referralYearCount.inProgressCount"
          :label="$t('label.deal.potential.year')"
          :sublabel="$t('label.deal.potential.period')"
        >
          <template v-slot:svg>
            <SvgIcon
              class="stats-widget-icon"
              icon-folder="Home"
              icon-name="Clock"
              icon-color="warning"
            />
          </template>
        </StatsWidget>
        <StatsWidget
          v-if="userAgentType === 'affiliated' || userAgentType === 'company'"
          class="stats-widget"
          :subcount="''"
          :count="`${parseFloat(userAgentAccrualPercentage)} %`"
          :label="$t('agent.percentages.company')"
        ></StatsWidget>
      </div>

      <div class="main-info-block">
        <StatsWidget
          class="mb-6 stats-widget"
          :subcount="dashboardData.referralCount.totalCount"
          :count="dashboardData.referralYearCount.totalCount"
          :label="$t('label.order.year')"
          :sublabel="$t('label.order.period')"
        >
          <template v-slot:svg>
            <SvgIcon
              class="stats-widget-icon"
              icon-folder="General"
              icon-name="Clipboard"
              icon-color="info"
            />
          </template>
        </StatsWidget>
        <StatsWidget
          class="stats-widget"
          :subcount="dashboardData.referralCount.wonCount"
          :count="dashboardData.referralYearCount.wonCount"
          :label="$t('label.deal.success.year')"
          :sublabel="$t('label.deal.success.period')"
        >
          <template v-slot:svg>
            <SvgIcon
              class="stats-widget-icon"
              icon-folder="Communication"
              icon-name="Clipboard-check"
              icon-color="success"
            />
          </template>
        </StatsWidget>
      </div>
    </div>

    <BalanceBlock
      class="balance-block"
      :balance="dashboardData.balance"
      :withdrawn="dashboardData.withdrawn"
      :requisite="dashboardData.requisite"
      @withdrawalCreated="onWithdrawalCreated"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReferralLinkBlock from "./ReferralLinkBlock";
import BalanceBlock from "./BalanceBlock";
import Modal from "./Modal";
import StatsWidget from "./StatsWidget";
import SvgIcon from "@/components/svg/SvgIcon";

export default {
  name: "Content",

  components: {
    BalanceBlock,
    ReferralLinkBlock,
    Modal,
    StatsWidget,
    SvgIcon,
  },

  props: {
    dashboardData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters(["userAgentAccrualPercentage", "userAgentType"]),
  },

  methods: {
    onWithdrawalCreated() {
      this.$emit("withdrawalCreated");
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-content {
  display: flex;
  min-width: 100%;

  .main-info {
    width: 100%;
    display: flex;

    .main-info-block {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    }

    .main-info-block + .main-info-block {
      margin-left: 24px;
    }
  }

  .balance-block {
    margin-left: 24px;
  }

  .stats-widget-icon {
    padding: 0;

    &:deep() {
      g [fill] {
        opacity: 1;
      }

      #Path,
      #Path-107,
      #Rectangle-1,
      #Rectangle-2,
      #check-path {
        fill: #ffffff !important;
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .dashboard-content {
    flex-wrap: wrap;

    .balance-block {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 720px) {
  .dashboard-content {
    .main-info {
      flex-wrap: wrap;

      .main-info-block + .main-info-block {
        margin-left: 0;
      }
    }
  }
}
</style>
