<template>
  <PageModal
    ref="dashboardModal"
    modal-id="dashboardModal"
    class="dashboard-modal"
    modal-size="xl"
    is-centered
    hide-header
    hide-footer
    body-class="p-0"
    content-class="rounded-lg"
    @changeShownModal="onChangeShownModal"
  >
    <div class="dashboard-modal-container">
      <div class="instr_modal-welc">
        <div>
          <p class="headline1">{{ $t("page.dashboard.modal.title") }}</p>
          <p>
            {{ $t("page.dashboard.modal.description.partFirst") }}
            <br />{{ $t("page.dashboard.modal.description.partSecond") }}<br />
            {{ $t("page.dashboard.modal.description.partThird") }}
          </p>
        </div>
      </div>

      <div class="instr_modal-info">
        <SvgIcon
          data-cy="dashboard-close-modal"
          class="instr_modal-close-icon"
          icon-folder="Navigation"
          icon-name="Close-3"
          icon-size="lg"
          @click="closeModal"
        />

        <p class="instr_modal-h3">{{ $t("page.dashboard.modal.subtitle") }}</p>
        <div class="insturct_step-head flex-s">
          <span>1</span>
          <p>{{ $t("page.dashboard.modal.stepFirst") }}</p>
        </div>

        <div class="insturct_step-head flex-s">
          <span>2</span>
          <p>
            {{ $t("page.dashboard.modal.stepSecond") }}
            <a href="https://www.atmosfera.ua/" style="color: #156fc1" target="_blank">{{
              $t("page.dashboard.modal.site")
            }}</a>
          </p>
        </div>

        <div class="insturct_step-head flex-s">
          <span>3</span>
          <p>
            {{ $t("page.dashboard.modal.stepThird") }}
          </p>
        </div>

        <div class="insturct_step-head flex-s">
          <span>4</span>
          <p>{{ $t("page.dashboard.modal.stepFourth") }}</p>
        </div>

        <div class="but but_mod" @click="onClickStart">{{ $t("button.start") }}</div>
      </div>
    </div>
  </PageModal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import "@/assets/css/main.css";

import PageModal from "@/components/modal/PageModal.vue";
import SvgIcon from "@/components/svg/SvgIcon.vue";

export default {
  name: "Modal",

  components: {
    SvgIcon,
    PageModal,
  },

  computed: {
    ...mapGetters(["newUserState", "userProfile"]),
  },

  watch: {
    newUserState: "onChangeNewUserState",
    userProfile: {
      handler: function () {
        if (this.userProfile.detail.passwordChanged != 0 && this.newUserState) {
          this.openModal();
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (this.newUserState && this.userProfile.detail.passwordChanged != 0) this.openModal();
  },

  beforeDestroy() {
    if (this.newUserState) this.closeModal();
  },

  methods: {
    ...mapMutations(["SAVE_NEW_USER_STATE", "SET_NEW_USER_STATE"]),

    onChangeShownModal(shownModal) {
      if (!shownModal && this.newUserState) this.SET_NEW_USER_STATE(true);
    },

    openModal() {
      this.$refs.dashboardModal.showModal();
    },

    onClickStart() {
      this.closeModal();
      this.$router.push({ name: "Instruction" });
    },

    closeModal() {
      this.$refs.dashboardModal.closeModal();
    },

    onChangeNewUserState() {
      this.SAVE_NEW_USER_STATE();
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-modal-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 599px;
  max-width: 1200px;
  position: relative;

  .instr_modal-welc {
    height: 100%;
    width: 50%;
    background: #5cc2bc;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .headline1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 64px;
  }

  .instr_modal-info {
    width: 50%;
    padding: 80px 40px;
  }

  .instr_modal-close-icon {
    position: absolute;
    top: 10px;
    right: 0;
    opacity: 0.7;
  }

  .instr_modal-h3 {
    font-weight: 700;
    font-size: 20px;
    padding-left: 48px;
    margin-bottom: 32px;
  }

  .instr_modal-info .insturct_step-head {
    margin-bottom: 24px;
  }

  .instr_modal-info .but {
    margin-top: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .dashboard-modal-container {
    flex-direction: column;
    height: 100%;

    .instr_modal-welc,
    .instr_modal-info {
      width: 100%;
    }

    .instr_modal-welc {
      text-align: center;
      margin: 0 auto;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 0;

      p {
        margin: 0 auto;
        width: 70%;
        white-space: normal;
      }

      .headline1 {
        margin-bottom: 40px;
      }

      br {
        display: none;
      }
    }

    .instr_modal-close-icon {
      opacity: 1;

      &:deep {
        g [fill] {
          fill: #d9d9d9 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .dashboard-modal-container {
    .instr_modal-info .but {
      margin: 60px auto 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-modal-container {
    height: auto;

    .instr_modal {
      align-items: stretch;
      flex-direction: column;
    }

    .instr_modal-welc {
      height: 100%;
      width: 100%;
      padding: 64px;
    }

    .instr_modal-welc > div br {
      display: none;
    }

    .instr_modal-info {
      width: 100%;
      padding: 48px 70px 80px;
    }

    .instr_modal-info .but {
      margin: 80px auto 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard-modal-container {
    .instr_modal-welc {
      width: 100%;
      padding: 64px 40px 40px;
    }

    .instr_modal-info {
      width: 100%;
      padding: 40px 24px 46px;
    }

    .instr_modal-info .but {
      margin: 50px auto 0;
    }
  }
}

@media (max-width: 600px) {
  .dashboard-modal-container {
    .headline1 {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }
}
</style>
